<template>
  <x-grid columns="5" columns-s="1f" class="apple">
  <AppHeader/>
  <AppLayout/>
  <AppFooter/>
  </x-grid>
</template>

<script>
import AppHeader from './components/AppHeader.vue'
import AppFooter from './components/AppFooter.vue'
import AppLayout from './components/AppLayout.vue'
export default {

  name: 'App',
  components: {
    AppHeader, AppFooter, AppLayout
  }
}

</script>

<style>

@import url('https://fonts.googleapis.com/css2?family=Josefin+Slab:wght@100&family=Rajdhani:wght@300&family=Wire+One&display=swap');
p{
  color: white;
  font-family: 'Rajdhani', sans-serif;
}

a {
  color: white;
  font-family: 'Josefin Slab', serif;
}
.apple {
  background: radial-gradient(ellipse at center, rgb(0, 0, 0) 0%, var(--bgColor) 100%);
  overflow: hidden;
  border-radius: var(--globalRadius);
  gap: 1px
}

.apple x-cell { min-height: 400px;
  background: var(--dark);
  padding: calc(var(--margin) * 2);
  display: grid;
  place-items: center
}

[class^="fs-"] { line-height: 1.1 }

.fs-200 { font-size: 2rem }
.fs-300 { font-size: 3rem }
.fs-500 { font-size: 5rem }
.fs-700 { font-size: 7rem }
.fs-900 { font-size: 9rem }

.clr-600 { color: #ffffff }

.size-100 { max-width: 25ch }
.size-200 { max-width: 45ch }
.size-700 { max-width: 70ch }

@media (max-width: 600px) {
  .apple x-cell { min-height: 200px }
  [class^="fs-"] { line-height: 1.4 }
  [class^="fs-"]:not(.fs-200, .fs-300) { font-size: 3.2rem }
  [class^="size-"] { max-width: 40ch }
}
</style>
