import { createApp } from 'vue'

import './assets/css/app.css'
import './assets/css/skelet.css'
import './assets/css/skelet.min.css'

import './assets/js/app'
import './assets/js/modules'

import App from './App.vue'

createApp(App).mount('#app')
