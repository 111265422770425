<template>
  <div class="size-100">
    <h2>Art</h2>
    <p class="fs-200 clr-600">power through complex tasks and preserve brain power.</p>
    <p class="button is-small is-white is-square">explore.</p>
</div>
</template>
<script>
export default {
  name: 'ArtSection'
}
</script>
<style>

</style>
