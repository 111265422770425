<template>
    <x-cell span="1-3" span-s="row">
      <ProjectsSection></ProjectsSection>
    </x-cell>
    <x-cell span="4..">
      <ArtSection></ArtSection>
    </x-cell>
    <x-cell span="1-2">
      <PhotographySection></PhotographySection>
    </x-cell>
    <x-cell span="3..">
      <BooksSection></BooksSection>
    </x-cell>
    <x-cell span="1-3">
      <SportsSection></SportsSection>
    </x-cell>
    <x-cell span="4..">
      <ConceptSection></ConceptSection>
    </x-cell>
</template>
<script>
import ProjectsSection from './sections/ProjectsSection.vue';
import ArtSection from './sections/ArtSection.vue';
import PhotographySection from './sections/PhotographySection.vue';
import BooksSection from './sections/BooksSection.vue';
import SportsSection from './sections/SportsSection.vue'
import ConceptSection from './sections/ConceptSection.vue'

export default {

  name: 'AppLayout',
  components: {
    ProjectsSection, ArtSection, PhotographySection, BooksSection, SportsSection, ConceptSection
  }
}
</script>
<style>
  h2 {
      font-family: 'Rajdhani', sans-serif;
      color: white;
  }
</style>
