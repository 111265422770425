<template>
  <div class="size-100">
    <h2>Photography</h2>
    <p>photography</p>
    <p class="button is-small is-white is-square">explore</p>
</div>
</template>
<script>
export default {
  name: 'PhotographySection'
}
</script>
<style>

</style>
