<template>
  <div class="size-100">
    <h2>Projects</h2>
    <p>...</p>
    <p class="button is-small is-white is-square">explore</p>
</div>
</template>

<script>
export default {
  name: 'ProjectsSection'
}
</script>
<style>

</style>
