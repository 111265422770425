<template>
  <div class="size-100">
    <h2 class="fs-300">Concept</h2>
    <p class="fs-200 clr-600">keeps things like your Siri requests and interactions with Live Text private.</p>
    <p class="button is-small is-white is-square">explore</p>
</div>
</template>
<script>
export default {
  name: 'ConceptSection'
}
</script>
<style>

</style>
