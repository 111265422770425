<template>
  <x-cell span="row">
    <div class="size-600">
        <h1>pedrito.me</h1>
        <p>"Be yourself; everyone else is already taken." ― Oscar Wilde</p>
    </div>
</x-cell>
</template>

<script>

export default {

  name: 'AppHeader',
  components: {

  }
}
</script>

<style>
  h1{
    font-family: 'Wire One', sans-serif;
    color: white;
    font-weight: 200;
    font-size: 9rem;
  }
</style>
