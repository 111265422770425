<template>
  <div class="size-200">
    <h2 class="fs-600">Books</h2>
    <p class="fs-200 clr-600">takes noise reduction and tone mapping to the next level.</p>
    <p class="button is-small is-white is-square">explore</p>
</div>
</template>
<script>
export default {
  name: 'BooksSection'
}
</script>
<style>

</style>
