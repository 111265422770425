<template>
  <div class="size-200">
    <h2 class="fs-600">Sports</h2>
    <p class="fs-200 clr-600">protects personal information like your Face ID data, contacts, and more</p>
    <p class="button is-small is-white is-square">explore</p>
</div>
</template>
<script>
export default {
  name: 'SportsSection'
}
</script>
<style>

</style>
