<template>
  <x-cell span="row">
    <div>
        <p>pedritome at pm.me</p>
    </div>
</x-cell>
</template>
<script>
export default {
  name: 'AppFooter',
  components: {

  }
}
</script>
<style>

</style>
